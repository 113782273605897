
















































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { UserStore, DemandSupplyStore, JWTStore } from "@/store/modules";
import { CompanyUser } from "@/store/models/user";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component
export default class SeedCompanyList extends Vue {
  pageNum: number = 1;
  dialogTitle: string = "";
  dialogVisible: boolean = false;
  rejectMessage: string = "";
  rejectDialogVisible: boolean = false;
  approvedStatus: string = "";
  tab: string = "seedCompany";

  user: CompanyUser = {} as CompanyUser;

  @Prop({ default: { seedCompany: false } })
  formVisible!: any;

  @Prop({ default: {} })
  dataList!: any;

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  // get dataList() {
  //   return UserStore.PendingSSPUsers;
  // }

  private get seedClass() {
    return DemandSupplyStore.SeedClassList;
  }

  get role() {
    return JWTStore.role;
  }

  handlePrevNextClick(val: any, list: string) {
    this.pageNum = val;
    this.$emit("handlePageChange", val, list);
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }

  addForm() {
    this.$router
      .push({
        name: "Seedcomregister"
      })
      .catch(error => {});
  }

  editForm(index: number, id: number) {
    this.$router
      .push({
        name: "Seedcomregister",
        query: { companyId: String(id), activeTab: "seedCompany" }
      })
      .catch(error => {});
  }

  async viewForm(index: number, id: number) {
    this.user = await UserStore.getCompanyByUserId(id);
    this.$router
      .push({
        name: "UserDetail",
        params: {
          userSlug: this.user.slug
        },
        query: {
          previous: this.$route.name
        }
      })
      .catch(error => {});
    // this.dialogVisible = true;
  }

  async approve() {
    if (this.user.id != undefined && this.user.id != 0) {
      await UserStore.approveCompanyById({
        id: this.user.id,
        status: true,
        seedtype: this.user.seedtype
      });
      this.$notify.success("Company is being approved. This may take some time.");
    }
    this.dialogVisible = false;
  }

  async reject() {
    this.rejectDialogVisible = true;
  }

  async sendRejectMessage() {
    this.rejectDialogVisible = false;
    this.dialogVisible = false;

    if (this.user.id != undefined && this.user.id != 0) {
      let params = {
        id: this.user.id,
        company_user: this.user.email,
        email_message: this.rejectMessage,
        status: false
      };
      await UserStore.rejectCompanyById(params);
      this.$notify.success("Mail sent successfully");
    }
  }

  onDeleteClick(index: any, id: number) {
    let users = this.dataList.results.filter((x: any) => x.id == id);
    if (users.length > 0) {
      let user = users[0];
      this.$confirm(
        "This will permanently delete the company " +
          user.name +
          " and all related data. Do you want to continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await UserStore.deleteCompanyUserById(id);
          this.$notify.success("User deleted");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }
}
